 


import React, { useState } from 'react';

const App = () => {
  const [createMessage, setCreateMessage] = useState('');
  const [getMessage, setGetMessage] = useState('');
  const [getId, setId] = useState('');

  function generateRandomChallenge(length) {
    const array = new Uint8Array(length);
    window.crypto.getRandomValues(array);
    return array;
  }

  const challengekey = generateRandomChallenge();
 
  const createCredential = async () => {
    try {
      const publicKey = {
        challenge: new Uint8Array([challengekey/* server-generated challenge */]),
        rp: { id: "willykat-001-site1.ftempurl.com", name: "ACME Corporation" },
        user: {
          id: new Uint8Array(["001"]),
          name: 'user@example.com',
          displayName: 'User Example',
        },
        pubKeyCredParams: [
          {
            type: 'public-key',
            alg: -7, // ES256 algorithm
          },
        ],
        authenticatorSelection: {
          authenticatorAttachment: 'platform', // Use platform authenticator
          userVerification: 'required', // Require user verification (e.g., fingerprint)
        },
        timeout: 60000, // 1 minute timeout
        attestation: 'direct', // Direct attestation
      };

      const credential = await navigator.credentials.create({ publicKey });
      if (credential) {
        setId(credential.id);
        setCreateMessage('Credential created successfully!');
        console.log(credential);
        // Send credential to server for storage
      } else {
        setCreateMessage('Credential creation failed.');
      }
    } catch (err) {
      console.error('Credential creation error:', err);
      setCreateMessage('Credential creation error.');
    }
  };

  const getCredential = async () => {
    try {
      console.log(challengekey);
      console.log(getId);

      const publicKey = {
        challenge: new Uint8Array([challengekey/* server-generated challenge */]),
        allowCredentials: [
          {
            id: new Uint8Array([getId/* credential ID from registration */]),
            type: 'public-key',
          },
        ],
        userVerification: 'required', // Require user verification (e.g., fingerprint)
        authenticatorSelection: {
          authenticatorAttachment: 'platform', // Use platform authenticator
          userVerification: 'required', // Require user verification (e.g., fingerprint)
        },
        timeout: 60000, // 1 minute timeout
        attestation: 'direct', // Direct attestation
      };

      const assertion = await navigator.credentials.get({ publicKey });
      if (assertion) {
        setGetMessage('Fingerprint authenticated!');
        // Perform additional actions (e.g., log the user in)
      } else {
        setGetMessage('Fingerprint authentication failed.');
      }
    } catch (err) {
      console.error('Fingerprint authentication error:', err);
      setGetMessage('Fingerprint authentication error.');
    }
  };

  return (
    <div>
      <h1>Fingerprint Authentication Example</h1>
      <div>
        <h2>Create Credential</h2>
        <button onClick={createCredential}>Create Credential</button>
        <p>{createMessage}</p>
      </div>
      <div>
        <h2>Get Credential</h2>
        <button onClick={getCredential}>Authenticate with Fingerprint</button>
        <p>{getMessage}</p>
      </div>
    </div>
  );
};

export default App;